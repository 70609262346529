<template>
  <div class="the_headert w-full h-full">
    <popupSuccess :want-to-make-offer="activeSuccess" @oga="retourSave" :titre="message"/>
    <popupSign :want-to-make-offer="activeSign" v-if="activeSign" @oga="retourSign"/>

    <div class="w-full h-full md:flex items-center justify-center tout">
      <div class="md:mr-8 cursor-pointer md:text-left text-center" @click="goBack()" v-if="steep !== 'resultat'">Annuler</div>
      <div class="md:mr-8 cursor-pointer md:text-left text-center save" @click="saveDevis()" v-if="steep === 'resultat'">Enregistrer mon devis</div>

      <div class="button" @click="goNext()" v-if="steep !== 'resultat'">
        <span v-if="steep === 'continuer' || steep === 'afterAnswer' || steep === 'recap'">Continuer</span>
        <span v-if="steep === 'prime'">Calculer ma prime</span>
      </div>

      <div class="button" @click="souscrire()" v-if="steep === 'resultat'">
        <span>Souscrire maintenant</span>
      </div>
    </div>
  </div>
</template>

<script>
import popupSign from '@/components/popup/popupSign'
import popupSuccess from '@/components/popup/popupSuccess'

export default {
  name: 'Index',
  components: {
    popupSign,
    popupSuccess
  },
  data () {
    return {
      activeSign: false,
      activeSuccess: false,
      message: 'Votre devis a bien été enregistré'
    }
  },
  props: {
    steep: {
      type: String,
      default: ''
    }
  },
  methods: {
    retourSign (answer) {
      this.activeSign = answer
    },

    goNext () {
      this.$emit('info', true)
    },

    goBack () {
      // this.$emit('back', true)
      this.$store.dispatch('position', 1)
      this.$store.dispatch('saveFirstDevis', null)
      this.$router.push('/')
    },

    retourSave (answer) {
      this.activeSuccess = answer
      this.$router.push('/devis')
    },

    souscrire () {
      const auth = this.$store.getters.tokenState
      if (auth !== null) {
        if (this.$store.getters.userConcern !== null) {
          this.goNext()
        } else {
          this.$router.push('/home')
        }
      } else {
        this.activeSign = true
      }
      this.$store.dispatch('addDevis', this.$store.getters.trackFirstDevis)
    },

    saveDevis () {
      this.$store.dispatch('addDevis', this.$store.getters.trackFirstDevis)
      this.activeSuccess = true
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/style/sass/variables";

    .the_headert {
        padding: 10px;
        width: 100%;
        height: auto;
        background-color: #fff;
        position: fixed;
        bottom: 0;
      box-shadow: -1px -1px 20px 0px #0000001a;
    }
    div{
      font-weight: 500;
      font-size: 18px;
      line-height: 34px;
      color: #303030;
      font-family: $font-default;
    }
.button{
  background-color: $base-color;
  border-radius: 10px;
  height: 53px;
  width: 17%;
  border: none;
  color: #FFFFFF;
}
.save:hover{
  color: $base-color;
}
.button:hover{
  background-color: $hover-button;
}
    @media screen and (max-width: 1024px) and (min-width: 701px){
        .the_headert {
            padding: 30px 0px 30px 0px;
        }
    }
    @media screen and (max-width: 769px) and (min-width: 701px){
      .tout{
        width: 100%;
      }
      .the_headert {
        padding: 10px 0px 10px 0px;
      }
      .button{
        width: 25%;
      }
    }
    @media screen and (max-width: 700px){
      .the_headert {
        padding: 0px 0px 0px 0px;
        font-size: 0.8em;
      }
      .tout{
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
      }
      .button{
        width: 100%;
      }
    }
</style>
