<template>
  <div class="home_page h-full">
<!--    <theHeader class="header-shadow"/>-->

    <div class="">
      <headerBack class="head2" :steep="steep" @info="retourInfo" v-if="steep !== 'charge' && steep !== 'resultat'" id="hed"/>
      <router-view class="mt-2" :retour="position" :suivant="suivant" @steep="retourSteep"/>
    </div>
     <footMenu class="foo" @back="retourInfo" @info="retourNext" :steep="steep" v-if="steep !== 'charge'"/>
  </div>
</template>

<script>
// import theHeader from '@/components/helper/header'
import footMenu from '@/components/helper/footerSteep'
import headerBack from '@/components/helper/headerBack'

export default {
  name: 'Home',
  components: {
    // theHeader,
    footMenu,
    headerBack
  },
  data () {
    return {
      position: 0,
      suivant: 0,
      steep: 'continuer'
    }
  },
  computed: {
    donnee: function () {
      return this.position
    }
  },
  methods: {
    retourSteep (answer) {
      this.steep = answer
      this.smooth()
    },
    retourInfo (answer) {
      this.position = this.position + 1
      this.smooth()
    },
    retourNext (answer) {
      this.suivant = this.suivant + 1
      this.smooth()
    },
    smooth () {
      const element = document.getElementById('hed')
      // element.scrollIntoView(true)
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/sass/variables";
.home_page {
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
}
.head2{
  //position: fixed;
}

@media screen and (max-width: 1440px) {
  .home_page {
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
  }
  .home_page::-webkit-scrollbar {
    display: none;
  }
  .header-shadow {
    position: fixed;
  }
  .footers {
    position: relative;
  }
}
</style>
